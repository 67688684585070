<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <van-tabs
      @click="onClickOnlyOne1"
      color="#02408f"
      title-active-color="#02408f"
      style="margin-bottom: 10px"
    >
      <!-- <van-tab name="全部" title="全部"> </van-tab> -->
      <van-tab
        v-for="item in List"
        :key="item.key"
        :name="item.name"
        :title="item.name"
      >
      </van-tab>
    </van-tabs>
    <div v-if="Show" class="essay">
      <div class="essayTitle" style="font-size: 18px">{{ list.Name }}</div>
      <div class="essayTitle" style="font-size: 14px; text-align: right">
        负责辖区: {{ list.CityName }}
      </div>
      <div v-if="list.Img" class="essayImg">
        <img :src="list.Img" alt="" @click="thematic(list.Img)" />
      </div>

      <div class="rikind">{{ list.TKName }}</div>

      <p class="essayContent" @click="imageEnlargement">
        {{ list.Introduce }}
      </p>
      <!-- v-html="list.Describe" -->
      <div class="video" v-if="list.Video">
        <video style="width: 100%" :src="list.Video" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="essayTitle" style="font-size: 14px; text-align: right">
        队长: {{ list.Legaler }}
      </div>
      <div class="essayTitle" style="font-size: 14px; text-align: right">
        队伍编号: {{ list.Code }}
      </div>
      <div class="essayTitle" style="font-size: 14px; text-align: right">
        求助电话:
        <a style="color: #000" :href="'tel:' + list.HelpTel">{{
          list.HelpTel
        }}</a>
      </div>
      <!-- <div class="rikind" style="text-align: right">
        阅读：{{ list.ReadNum }}
      </div> -->
      <!-- <div id="demo"
        class="m-pdf"></div> -->

      <!-- <iframe style="width:100%;height:78vh;" :src="pdf"></iframe> -->
    </div>

    <div v-else class="shop">
      <van-tabs
        @click="onClickOnlyOne"
        color="#02408f"
        title-active-color="#fff"
        type="card"
      >
        <!-- <van-tab name="全部" title="全部"> </van-tab> -->
        <van-tab
          v-for="item in CateList"
          :key="item.cate"
          :name="item.cate"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs>
      <div class="information">
        <!-- 全部 -->
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          :immediate-check="false"
          finished-text="没有更多了"
          :offset="100"
        >
          <div class="center" v-for="item in list1" :key="item.index">
            <!-- <router-link :to="'/huayang/index/' + item.TeamId"> -->
            <van-card style="padding: 12px; background-color: white">
              <template #thumb>
                <!-- <div> -->
                <img
                  v-if="!item.HeadImg"
                  style="width: 100%; height: 100%"
                  src="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/b204a1ec-678.png"
                />
                <img
                  v-else
                  style="width: 100%; height: 100%"
                  :src="item.HeadImg.split(',')[0]"
                />
                <!-- </div> -->
              </template>
              <template #title>
                <div
                  class="centerTitle van-ellipsis--l1"
                  style="width: 75%; padding-bottom: 6px"
                >
                  {{ item.TeamName }}
                </div>
              </template>

              <template #tags>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 65%;
                  "
                >
                  <div
                    style="
                      padding-bottom: 1px;
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/cce14aa0-b30.png"
                    />
                    姓名：{{ item.Name }}
                  </div>
                  <div
                    v-if="item.DutiesDesc"
                    style="
                      padding-bottom: 1px;
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <van-icon
                      name="http://alycommunity.anluoyun.cn/lc-lcxms/2023/12/2aae12f7-861.png"
                    />
                    职务：{{ item.DutiesDesc }}
                  </div>
                  <div
                    style="
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/e21aac96-379.png"
                    />
                    蓝天编号：{{ item.Code }}
                  </div>
                </div>
                <!-- <div
          
                  style="
                    /* padding-bottom: 7px; */
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                  "
                >
                  <van-icon
                    name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/591913d2-404.png"
                  />
                  职务：{{ item.DutiesDesc }}
                </div> -->
                <!-- <div
                  class="van-multi-ellipsis--l2"
                  v-if="item.Address != ''"
                  style="width: 75%; padding-bottom: 5px; font-size: 13px"
                >
                  <van-icon name="location" /> {{ item.Address }}
                </div> -->
                <!-- <div class="county"
                @click="gotominiProgram(item)">
                <img src="https://xczxapi.zhsq.cloud//upload/images/2022/6/b10693b0-c37.png"
                  alt=""
                  style="width: 24px;">
              </div> -->
              </template>
              <!-- <template #footer v-if="riKind == '3' && item.DutiesDesc">
                <van-button size="small" type="info">{{
                  item.DutiesDesc
                }}</van-button>
              </template> -->
            </van-card>
            <!-- </router-link> -->
          </div>
        </van-list>
        <!-- 没有更多数据了 -->
        <!-- <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div> -->
      </div>
    </div>
    <div class="footer" v-if="Show">
      <van-button type="info" @click="jion">我要入队</van-button>
      <!-- <van-button type="info" @click="duiyuan" :class="!Show ? 'clicked' : ''"
        >队员</van-button
      > -->
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import { WxGetTeamInfo, WxGetTeMemberPage } from "@/api/RealInfo";
import Pdfh5 from "pdfh5";
import { getOpenId, setOpenId, getwgToken, setwgToken } from "@/utils/auth";
export default {
  data() {
    return {
      riKind: "3",
      loading: false, //加载
      CateList: [
        {
          cate: 3,
          Name: "正式队员",
        },
        {
          cate: 1,
          Name: "志愿者",
        },
        {
          cate: 2,
          Name: "预备队员",
        },
      ],
      List: [
        {
          key: 1,
          name: "队伍",
        },
        {
          key: 2,
          name: "队员",
        },
      ],
      Show: true,
      list: {},
      list1: [],
      pdf: "",
      pdfh5: "",
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      hidden: false, //没有更多数据了
    };
  },
  methods: {
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.riKind = name;
      this.list1 = [];
      this.finished = false;
      this.listfrom.page = 1;
      this.getList();
    },
    onClickOnlyOne1(name, title) {
      console.log(name);
      if (name == "队伍") {
        this.getRealInfo();
        this.Show = true;
      }
      if (name == "队员") {
        this.getList();
        this.Show = false;
      }
    },
    jion() {
      // var token = getwgToken();
      // if (token) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../index/join/index?teamId=" + this.listfrom.teamId,
        });
      }
      // } else {
      //   Toast.fail("您还未登录,请先去登录!");
      // }
    },
    thematic(e) {
      ImagePreview({
        images: [e], // 传递的是图片集数组
        startPosition: 0, // 图片索引位置
        closeable: true, // 是否显示关了按钮
        showIndicators: true, // 是否显示轮播指示器
        loop: false, // 不设置为false,首图和尾部图不能正常加载
      });
    },
    // 富文本图片内容 点击放大
    imageEnlargement(e) {
      if (e.target.nodeName === "IMG") {
        ImagePreview({
          images: [e.target.src], // 传递的是图片集数组
          startPosition: 0, // 图片索引位置
          closeable: true, // 是否显示关了按钮
          showIndicators: true, // 是否显示轮播指示器
          loop: false, // 不设置为false,首图和尾部图不能正常加载
        });
      }
    },

    // 获取分页列表
    getList() {
      this.listfrom.cate = this.riKind;
      this.listfrom.name = this.search;
      WxGetTeMemberPage(this.listfrom)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 0) {
            this.total = res.data.count;
            let rows = res.data.data; //请求返回当页的列表
            // 加载状态结束

            if (rows == null || rows.length === 0) {
              // 加载结束
              console.log("结束");
              this.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.list1 = this.list1.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list1.length >= res.data.count) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {});
    },
    getRealInfo() {
      this.listfrom.teamId = this.$route.params.id;
      this.listfrom.cate = this.riKind;
      WxGetTeamInfo(this.listfrom).then((res) => {
        this.list = res.data.data;
        console.log(this.list, "this.list");
        // axios.default.baseURL = 'https://api.qxzhqm.cn//upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        // this.pdf = axios.default.baseURL + '/upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        this.pdf = "/upload/files/2022/3/ThingJS-X应用模板制作标准.pdf";
        // this.pdf = '/upload/files/2022/3/20211217-老城区民营经济综合服务平台（PC端）(1)(1).docx'
        console.log(this.pdf);
        // this.pdfh5 = new Pdfh5("#demo", {
        //   pdfurl: "../api" + this.pdf,
        // });
        //监听完成事件
        // this.pdfh5.on("complete", function (status, msg, time) {
        //   console.log(
        //     "状态：" +
        //       status +
        //       "，信息：" +
        //       msg +
        //       "，耗时：" +
        //       time +
        //       "毫秒，总页数：" +
        //       this.totalNum
        //   );
        // });
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
      // this.activated();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    activated() {
      var _this = this;
      _this.uuid = _this.$route.query.uuid;
      var shareUrl = global.BASE_SHARE_URL + "grade?uuid=" + _this.uuid;
      this.$wxShare.updateWxShareConfig({
        title: "标题",
        desc: "简介",
        link: shareUrl,
      });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
<style scoped>
.essay .essayContent img {
  display: block;
  width: 100%;
}
.pageNum-num {
  display: none;
}
.m-pdf img {
  width: 100%;
}

.footer {
  text-align: center;
  padding-bottom: 26px;
}

.footer button {
  width: 90%;
}
.footer button:first-child {
  margin-right: 1%;
  margin-left: 1%;
}
.footer button:last-child {
  margin-right: 1%;
}

.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}

/deep/ .van-button--info {
  background-color: #02408f;
  border: 1px solid #02408f;
  border-radius: 24px;
}
.clicked {
  background-color: #fff;
  border: 1px solid #02408f;
  color: #02408f;
}
.shop {
  background: #f8f8f8;
  min-height: 100vh;
  padding-top: 10px;
}
.van-card__thumb {
  margin-right: 11px;
  width: 65.5px;
  height: 86.5px;
}
.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  /* background: rgb(246, 243, 243); */
}
.centertime {
  font-size: 14px !important;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}

/deep/ .van-card {
  border-radius: 8px;
}

.centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1b2024;
  padding-top: 4px;
}

/deep/ .van-icon__image {
  width: 1.2em;
  height: 1.2em;
}

/deep/ .van-icon {
  vertical-align: middle;
  margin-right: 6px;
}

/deep/ .van-card__footer {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.essay {
  padding-bottom: 50px;
}

/deep/ .van-tabs__nav--card {
  border-radius: 14px;
  border: none;
}

/deep/ .van-tabs__nav--card .van-tab .van-tab--active {
  /* border-radius: 9px; */
  /* border-right: none; */
}

/deep/ .van-tabs__nav--card .van-tab {
  border-right: none;
  border-radius: 14px;
}
</style>
